<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户名称">
              <a-select
                showSearch
                allowClear
                placeholder="请选择客户名称"
                style="width: 100%"
                v-model="queryParam.customer_id"
              >
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="装货工厂">
              <a-select
                showSearch
                allowClear
                placeholder="请选择装货工厂"
                style="width: 100%"
                v-model="queryParam.factory_id"
              >
                <a-select-option v-for="op in factoryOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="business_type" slot-scope="text">
        {{ bizTypeMap[text] && bizTypeMap[text].name }}
      </span>
      <span slot="fee_type" slot-scope="text">
        {{ feeTypeMap[text] && feeTypeMap[text].name }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal :width="640" :visible="formVisible"
             :title="modalFlag === 'create' ? '创建固定费用' : '编辑固定费用'"
             :confirmLoading="confirmLoading"
             @ok="handleSubmit" @cancel="formVisible = false">
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="客户名称" prop="customer_id">
              <a-select v-model="form.customer_id" showSearch
                        ref="customer"
                        allowClear style="width: 100%">
                <a-select-option v-for="cs in customerOps" :key="cs.value">{{ cs.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="装货工厂">
              <a-select v-model="form.factory_id" showSearch
                        ref="factory"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in factoryOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="业务方向">
              <a-select v-model="form.business_type" allowClear style="width: 100%" @change="v => getFeeOptions(v, 'biz')">
                <a-select-option v-for="b in bizTypeMap" :key="b.value">{{ b.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用类型">
              <a-select v-model="form.fee_type" allowClear style="width: 100%" @change="v => getFeeOptions(v, 'fee')">
                <a-select-option v-for="v in feeTypeMap" :key="v.value">{{ v.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="箱型">
              <a-select v-model="form.box_info_id" showSearch
                        ref="box"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in boxInfoOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="装货地点">
              <a-select v-model="form.location_id" showSearch
                        ref="location"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in locationOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="费用名称">
              <a-select v-model="form.fee_id" showSearch
                        ref="fee"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in feeOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="金额" prop="amount">
              <a-input placeholder="" v-model="form.amount"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getMetaInfoPage, getMetaOptions, deleteMetaTypeInfo, createMetaTypeInfo, updateMetaTypeInfo } from '@/api/wms/meta'
import { getCommonOptions } from '@/api/common'
import { validateMoney } from '@/utils/validate'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      customerOps: [],
      factoryOps: [],
      boxInfoOps: [],
      locationOps: [],
      feeOps: [],
      bizTypeMap: {
        1: { value: 1, name: '出口' },
        2: { value: 2, name: '进口' }
      },
      feeTypeMap: {
        1: { value: 1, name: '应付' },
        2: { value: 2, name: '应收' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '装货工厂',
          dataIndex: 'factory_name'
        },
        {
          title: '业务方向',
          dataIndex: 'business_type',
          scopedSlots: { customRender: 'business_type' }
        },
        {
          title: '费用类型',
          dataIndex: 'fee_type',
          scopedSlots: { customRender: 'fee_type' }
        },
        {
          title: '箱型',
          dataIndex: 'box_info_name'
        },
        {
          title: '装货地点',
          dataIndex: 'location_name'
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '金额',
          dataIndex: 'amount'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getMetaInfoPage('fixed_fee', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        customer_id: null,
        factory_id: null,
        business_type: null,
        fee_type: null,
        box_info_id: null,
        location_id: null,
        fee_id: null,
        amount: null
      },
      rules: {
        customer_id: [
          { required: true, message: '请选择客户名称', trigger: 'blur' }
        ],
        amount: [
          { validator: this.checkAmount }
        ]
      },
      formVisible: false,
      confirmLoading: false,
      currentData: null,
      modalFlag: 'create'
    }
  },
  created() {
    // getCommonOptions('customer').then(v => {
    //   this.customerOps = v
    // })
    getMetaOptions('factory').then(f => {
      this.factoryOps = f
    })
  },
  methods: {
    checkAmount(rule, value, callback) {
      if (validateMoney(value)) {
        callback()
      } else {
        callback(new Error('请输入合法的金额'))
      }
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.formVisible = true
      this.form = {
        customer_id: null,
        factory_id: null,
        business_type: null,
        fee_type: null,
        box_info_id: null,
        location_id: null,
        fee_id: null,
        amount: null
      }
      this.getFormBaseInfo()
      // this.$nextTick(_ => {
      //   this.$refs.form.resetFields()
      // })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      // this.currentData = record
      this.formVisible = true
      this.form = record
      this.getFormBaseInfo()
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = { ...this.form,
            box_info_name: this.$refs['box'].$el.innerText,
            customer_name: this.$refs['customer'].$el.innerText,
            factory_name: this.$refs['factory'].$el.innerText,
            fee_name: this.$refs['fee'].$el.innerText,
            location_name: this.$refs['location'].$el.innerText
          }
          this.confirmLoading = true
          let func = createMetaTypeInfo
          if (this.modalFlag === 'update') {
            func = updateMetaTypeInfo
          }
          func('fixed_fee', formData).then(u => {
            this.confirmLoading = false
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'create' ? '创建成功.' : '编辑成功'
            })
            this.$refs.table.refresh(true)
          }).catch(_ => {
            this.formVisible = false
            this.confirmLoading = false
          })
        }
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteMetaTypeInfo('fixed_fee', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    getFormBaseInfo() {
      if (this.boxInfoOps.length === 0) {
        getCommonOptions('box_info').then(v => {
          this.boxInfoOps = v
        })
      }
      if (this.locationOps.length === 0) {
        getMetaOptions('location').then(v => {
          this.locationOps = v
        })
      }
      const biztype = this.form.business_type
      const feetype = this.form.fee_type
      if (biztype && feetype) {
        getCommonOptions('fee', {
          business_type: biztype,
          feetype: feetype,
          is_fixed: true
        }).then(v => {
          this.feeOps = v
        })
      }
    },
    getFeeOptions(val, flag) {
      this.feeOps = []
      const biztype = flag === 'biz' ? val : this.form.business_type
      const feetype = flag === 'fee' ? val : this.form.fee_type
      if (biztype && feetype) {
        getCommonOptions('fee', {
          business_type: biztype,
          feetype: feetype,
          is_fixed: true
        }).then(v => {
          this.feeOps = v
        })
      } else {
        // 2
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
